
import { FC, useState, useEffect } from 'react';
import { Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Delete';
import { Flow } from 'src/models/flow';
import { DeclineProfile } from 'src/models/declineProfile';

interface FlowSectionProps {
    flow: Partial<Flow>
    position:number
    onRemoveFlow:(position:number) => void
    onChangeFlow:(position:number, flow:Partial<Flow>) => void
    declineProfiles:DeclineProfile[]
}

const FlowSection: FC<FlowSectionProps> = ({ flow, position, onRemoveFlow, onChangeFlow, declineProfiles }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [rebillsEnabled, setRebillsEnabled] = useState(false)
    const [declineProfilesEnabled, setDeclineProfilesEnabled] = useState(false)
    const [declineProfileIDs, setDeclineProfileIDs] = useState<string[]>([]);
    const [threedsEnabled, setThreedsEnabled] = useState(false)
    const [rebillThreedsEnabled, setRebillThreedsEnabled] = useState(false)
    const [threedsDeclineRouteEnabled, setThreedsDeclineRouteEnabled] = useState(false)
    const [rebillThreedsDeclineRouteEnabled, setRebillThreedsDeclineRouteEnabled] = useState(false)
    const [prepaidDeclineRouteEnabled, setPrepaidDeclineRouteEnabled] = useState(false)
    
    useEffect(() => {
        setRebillsEnabled(flow.rebill_frequency_days && flow.rebill_frequency_days !== '')
        setDeclineProfilesEnabled(flow.decline_profiles && flow.decline_profiles.length > 0)
        if(flow.decline_profiles && flow.decline_profiles.length > 0){
            var decline_profile_ids = flow.decline_profiles.map(dp => `${dp.id}`)
            setDeclineProfileIDs(decline_profile_ids)
            flow.decline_profile_ids = decline_profile_ids
        }
        setThreedsEnabled(flow.threeds_amount && flow.threeds_amount !== '')
        setRebillThreedsEnabled(flow.rebill_threeds_amount && flow.rebill_threeds_amount !== '')
        setThreedsDeclineRouteEnabled(flow.threeds_decline_external_offer_id && flow.threeds_decline_external_offer_id !== '')
        setRebillThreedsDeclineRouteEnabled(flow.rebill_threeds_decline_external_offer_id && flow.rebill_threeds_decline_external_offer_id !== '')
        setPrepaidDeclineRouteEnabled(flow.prepaid_decline_campaign_id && flow.prepaid_decline_campaign_id !== '')
    }, [flow])

    const handleDeclineProfilesChange = (event: SelectChangeEvent<typeof declineProfileIDs>) => {
        const {
          target: { value },
        } = event;
        // On autofill we get a stringified value.
        const dr = typeof value === 'string' ? value.split(',') : value
        console.log(dr)
        setDeclineProfileIDs(dr)
        flow.decline_profile_ids = dr
        onChangeFlow(position, flow)
    }

    return (
        <>
            <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                <Grid item xs={2}>
                    <Divider />
                </Grid>
                    <Grid item xs={position > 1 ? 1 : 2}>
                        <Typography variant='h6'>Flow {position} (id: {flow.id})</Typography>
                    </Grid>
                    {position > 1 && <Grid item xs={1}>
                        <LoadingButton
                            loadingPosition='start'
                            startIcon={<CancelIcon />}
                            variant='outlined'
                            color='error'
                            size='small'
                            onClick={() => onRemoveFlow(position)}
                        >Remove Flow
                        </LoadingButton>
                    </Grid>}
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Campaign ID"
                            value={flow.external_campaign_id}
                            type='number'
                            onChange={(e) => {
                                flow.external_campaign_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Initial Offer ID"
                            value={flow.external_initial_offer_id}
                            type='number'
                            onChange={(e) => {
                                flow.external_initial_offer_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Offer ID"
                            value={flow.external_offer_id}
                            type='number'
                            onChange={(e) => {
                                flow.external_offer_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="First charge in"
                            value={flow.trial_days}
                            type='number'
                            onChange={(e) => {
                                flow.trial_days = e.target.value
                                if(parseInt(flow.trial_days) == 0){
                                    flow.charge_delay = '0'
                                } else {
                                    flow.trial_days_random = '2'
                                }
                                onChangeFlow(position, flow)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    {flow.trial_days != undefined && parseInt(flow.trial_days) > 0 && <Grid item xs={1}>
                        <TextField
                            label="First charge day randomized by +/-"
                            value={flow.trial_days_random}
                            type='number'
                            defaultValue='2'
                            placeholder='2'
                            onChange={(e) => {
                                flow.trial_days_random = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid> }
                    {flow.trial_days != undefined && parseInt(flow.trial_days) == 0 && <Grid item xs={1}>
                        <TextField
                            label="Charge delay"
                            value={flow.charge_delay}
                            type='number'
                            defaultValue='0'
                            placeholder='0'
                            onChange={(e) => {
                                flow.charge_delay = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid> }
                </Grid>
                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                    <Grid item xs={1}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={threedsEnabled} checked={threedsEnabled} onChange={(event, checked) => {
                                setThreedsEnabled(checked)
                                if (!checked) {
                                    flow.threeds_campaign_id = ''
                                    flow.threeds_amount = ''
                                    onChangeFlow(position, flow)
                                }
                            }} />} label="Enable 3DS" />
                        </FormGroup>
                    </Grid>
                    {window.config.ENABLE_3DS_DECLINE_ROUTE === true && threedsEnabled && 
                        <Grid item xs={1}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox value={threedsDeclineRouteEnabled} checked={threedsDeclineRouteEnabled} onChange={(event, checked) => {
                                    setThreedsDeclineRouteEnabled(checked)
                                    if (!checked) {
                                        flow.threeds_decline_external_offer_id = ''
                                        onChangeFlow(position, flow)
                                    }
                                }} />} label="Enable 3DS Decline Route" />
                            </FormGroup>
                        </Grid>
                    }
                </Grid>
                {threedsEnabled && <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            label="3DS Campaign ID"
                            value={flow.threeds_campaign_id}
                            onChange={(e) => {
                                flow.threeds_campaign_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>
                    { window.config.ENABLE_3DS_DECLINE_ROUTE === true && threedsDeclineRouteEnabled && 
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Offer ID"
                            value={flow.threeds_decline_external_offer_id}
                            onChange={(e) => {
                                flow.threeds_decline_external_offer_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>}
                </Grid>}
                 {threedsEnabled && <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            label="3DS Authorization Amount"
                            value={flow.threeds_amount}
                            onChange={(e) => {
                                flow.threeds_amount = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>}
                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                    <Grid item xs={2}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={rebillsEnabled} checked={rebillsEnabled} onChange={(event, checked) => {
                                setRebillsEnabled(checked)
                                if (!checked) {
                                    flow.rebill_external_offer_id = ''
                                    flow.rebill_frequency_days = ''
                                    onChangeFlow(position, flow)
                                }
                            }} />} label="Enable Rebills" />
                        </FormGroup>
                    </Grid>
                </Grid>
                {rebillsEnabled && <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Offer ID"
                            value={flow.rebill_external_offer_id}
                            onChange={(e) => {
                                flow.rebill_external_offer_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Rebill Frequency every"
                            value={flow.rebill_frequency_days}
                            onChange={(e) => {
                                flow.rebill_frequency_days = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Rebill charge day randomized by +/-"
                            value={flow.rebill_frequency_days_random}
                            type='number'
                            defaultValue='2'
                            placeholder='2'
                            onChange={(e) => {
                                flow.rebill_frequency_days_random = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={rebillThreedsEnabled} checked={rebillThreedsEnabled} onChange={(event, checked) => {
                                setRebillThreedsEnabled(checked)
                                if (!checked) {
                                    flow.rebill_threeds_amount = ''
                                    onChangeFlow(position, flow)
                                }
                            }} />} label="Enable 3DS for rebill" />
                        </FormGroup>
                    </Grid>
                    {window.config.ENABLE_3DS_DECLINE_ROUTE === true && rebillThreedsEnabled && 
                        <Grid item xs={1}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox value={rebillThreedsDeclineRouteEnabled} checked={rebillThreedsDeclineRouteEnabled} onChange={(event, checked) => {
                                    setRebillThreedsDeclineRouteEnabled(checked)
                                    if (!checked) {
                                        flow.rebill_threeds_decline_external_offer_id = ''
                                        onChangeFlow(position, flow)
                                    }
                                }} />} label="Enable 3DS Decline Route" />
                            </FormGroup>
                        </Grid>
                    }
                 {rebillThreedsEnabled && <Grid item xs={1}>
                        <TextField
                            label="3DS Authorization Amount"
                            value={flow.rebill_threeds_amount}
                            onChange={(e) => {
                                flow.rebill_threeds_amount = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$</InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    }
                    { window.config.ENABLE_3DS_DECLINE_ROUTE === true && rebillThreedsDeclineRouteEnabled && 
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Offer ID"
                            value={flow.rebill_threeds_decline_external_offer_id}
                            onChange={(e) => {
                                flow.rebill_threeds_decline_external_offer_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>}
                </Grid>}
                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                    <Grid item xs={2}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={declineProfilesEnabled} checked={declineProfilesEnabled} onChange={(event, checked) => {
                                setDeclineProfilesEnabled(checked)
                                if (!checked) {
                                    setDeclineProfileIDs([])
                                    flow.decline_profile_ids = []
                                    onChangeFlow(position, flow)
                                }
                            }} />} label="Enable Decline Profiles" />
                        </FormGroup>
                    </Grid>
                </Grid>
                {declineProfilesEnabled && <Grid container spacing={1} columns={{ xs: 1 }} mb={1}>
                <FormControl sx={{
                    width:'100%',
                    mt: 1,
                    mb: 1,
                }}>
                    <InputLabel id="demo-multiple-chip-label">Decline Reasons</InputLabel>
                    <Select
                        multiple
                        value={declineProfileIDs}
                        onChange={handleDeclineProfilesChange}
                        input={<OutlinedInput label="Decline Reasons" />}
                        renderValue={(selected) => { 
                            return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                const declineProfile = declineProfiles.find(dc => dc.id == parseInt(value))
                                return (
                                <Chip key={value} label={declineProfile.name} />
                            )})}
                            </Box>
                        )}}
                        //MenuProps={MenuProps}
                    >
                    {declineProfiles.map((declineProfile) => (
                        <MenuItem
                        key={declineProfile.id}
                        value={`${declineProfile.id}`}
                        >
                        {declineProfile.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid>}
                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                    <Grid item xs={1}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={prepaidDeclineRouteEnabled} checked={prepaidDeclineRouteEnabled} onChange={(event, checked) => {
                                setPrepaidDeclineRouteEnabled(checked)
                                if (!checked) {
                                    flow.prepaid_decline_campaign_id = ''
                                    onChangeFlow(position, flow)
                                }
                            }} />} label="Enable Prepaid Decline Routing" />
                        </FormGroup>
                    </Grid>
                </Grid>
                 {prepaidDeclineRouteEnabled && <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            label="Prepaid Decline Campaign ID"
                            value={flow.prepaid_decline_campaign_id}
                            onChange={(e) => {
                                flow.prepaid_decline_campaign_id = e.target.value
                                onChangeFlow(position, flow)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>}
        </>
    )
}

export default FlowSection;

