import { Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography, styled, Backdrop, CircularProgress, Box, Tabs, Tab } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Order, OrderWasBilled } from 'src/models/order';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from 'src/components/FormInput';
import CancelIcon from '@mui/icons-material/Cancel';
import ResumeIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useMemo, useState } from 'react';
import { OrdersService } from 'src/api/services/OrdersService';
import GridFormInput from 'src/components/GridFormInput';
import { format } from 'date-fns';
import { Customer } from 'src/models/customer';
import { CustomersService } from 'src/api/services/CustomersService';

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

function CustomerDetailsHeader({customer, onCustomerUpdated}:{customer:Customer, onCustomerUpdated:(customer:Customer) => void}) {
  const defaultValues = {
    firstName: customer.firstName,
    lastName: customer.lastName,
    email: customer.email,
    /*phone: order.phone,
    billingAddress1: order.billingAddress1,
    billingAddress2: order.billingAddress2,
    billingCity: order.billingCity,
    billingState: order.billingState,
    billingZip: order.billingZip,
    campaignId: order.campaignId,
    status: order.status,
    billDate: format(new Date(order.trial_ends_at), 'M/d/yyyy h:mm a'),*/
  };

  const methods = useForm({
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<Partial<Customer>> = async (values: Partial<Customer>) => {
    setIsSaving(true)
    const formValues = methods.control._formValues
    const updatedCustomer = await CustomersService.update({
      id: customer.id,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
    });
    onCustomerUpdated(updatedCustomer)
    setIsSaving(false)
  };

  /*const cancelTrial = async () => {
    setOpenCancelTrial(true);
  }

  const confirmTrialCancellation = async () => {
    setIsCancellingTrial(true)
    try{
      const updatedOrder = await OrdersService.cancelTrial(order.id, {
        cancellationNotes:cancellationNotes.length > 0 
        ? cancellationNotes 
        : 'Cancelled by customer support'})
      onOrderUpdated(updatedOrder)
      handleCloseTrial()
    } catch(e){
      console.error(e)
    }
    setIsCancellingTrial(false)
  }

  const resumeTrial = async () => {
    setOpenResumeTrial(true);
  }

  const confirmTrialResuming = async () => {
    setIsResumingTrial(true)
    try{
      const updatedOrder = await OrdersService.resumeTrial(order.id)
      onOrderUpdated(updatedOrder)
      handleCloseTrial()
    } catch(e){
      console.error(e)
    }
    setIsResumingTrial(false)
  }
  */

  const [isCancellingTrial, setIsCancellingTrial] = useState(false);
  const [isResumingTrial, setIsResumingTrial] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openCancelTrial, setOpenCancelTrial] = useState(false);
  const [openResumeTrial, setOpenResumeTrial] = useState(false);
  const [cancellationNotes, setCancellationNotes] = useState('');

  const handleCloseTrial = () => {
    setOpenCancelTrial(false);
    setOpenResumeTrial(false);
  };

  /*const trialActionNode = useMemo(() => {
    if(OrderWasBilled(order)){
      return 'No trial, already billed'
    }
    if(order.trial_cancelled_at == undefined){
      return <LoadingButton
        loading={isCancellingTrial}
        loadingPosition='start'
        startIcon={<CancelIcon />}
        variant='outlined'
        color='error'
        onClick={cancelTrial}
      >Cancel Trial</LoadingButton>
    }
    if(order.trial_cancelled_at != undefined && OrderWasBilled(order) == false){
      return <LoadingButton
        loading={isResumingTrial}
        loadingPosition='start'
        startIcon={<ResumeIcon />}
        variant='outlined'
        color='success'
        onClick={resumeTrial}
      >Resume Trial</LoadingButton>
    }
  }, [order])*/
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isSaving}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Details" value="1" />
            <Tab label="Cards" value="2" />
          </Tabs>
        </Box>
        <TabPanel value="1">
        <form onSubmit={methods.handleSubmit(onSubmitHandler)} >
        <CardContent>
      <Grid container spacing={1} columns={{ xs: 1, sm: 2, md: 4 }}>
        <FormProvider {...methods} >
        <GridFormInput
          label='First Name'
          name='firstName'
          required
        />
        <GridFormInput
          label='Last Name'
          name='lastName'
          required
        />
        <GridFormInput
          label='Email'
          type='email'
          name='email'
          disabled
        />  
        </FormProvider>
      </Grid>
      </CardContent>
      <Divider/>
      <CardActions  sx={{ justifyContent:'flex-end' }} >
        <LoadingButton
          loading={isSaving}
          loadingPosition='start'
          startIcon={<SaveIcon />}
          variant='outlined'
          type='submit'
        >Save</LoadingButton>
      </CardActions>
      </form>
        </TabPanel>
        <TabPanel value="2">
          {customer.credit_cards && customer.credit_cards.map(cc => {
            return <Box key={cc.id} mb={2}>
              <Typography>{JSON.stringify(cc)}</Typography>
            </Box>
          })}
        </TabPanel>
      </TabContext>
      </Card>
      {/*<Dialog open={openCancelTrial} onClose={handleCloseTrial}>
        <DialogTitle>Cancel Trial</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText marginBottom={'1rem'}>
          Are you sure you want to cancel this customer subscription?
          </DialogContentText>
          <TextField
            autoFocus
            label="Cancellation reason"
            value={cancellationNotes}
            onChange={(e) => {
              setCancellationNotes(e.currentTarget.value)
            }}
            fullWidth
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseTrial}>No</Button>
          <LoadingButton
            loading={isCancellingTrial}
            loadingPosition='start'
            startIcon={<CancelIcon />}
            color='error'
            onClick={confirmTrialCancellation}
          >Yes, cancel it</LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openResumeTrial} onClose={handleCloseTrial}>
        <DialogTitle>Resume Trial</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText marginBottom={'1rem'}>
          Are you sure you want to resume this customer subscription?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseTrial}>No</Button>
          <LoadingButton
            loading={isResumingTrial}
            loadingPosition='start'
            startIcon={<ResumeIcon />}
            color='success'
            onClick={confirmTrialResuming}
          >Yes, resume it</LoadingButton>
        </DialogActions>
      </Dialog>*/}
    </>
  );
}

export default CustomerDetailsHeader;
