
import { FC, useState, useEffect } from 'react';
import { Autocomplete, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Delete';
import { DeclineAttempt } from 'src/models/declineAttempt';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { DeclineProfileCampaign } from 'src/models/declineProfileCampaign';

interface DeclineAttemptSectionProps {
    declineAttempt: Partial<DeclineAttempt>
    position:number
    onRemoveDeclineAttempt:(position:number) => void
    onChangeDeclineAttempt:(position:number, declineAttempt:Partial<DeclineAttempt>) => void
}

const DeclineAttemptSection: FC<DeclineAttemptSectionProps> = ({ declineAttempt, position, onRemoveDeclineAttempt, onChangeDeclineAttempt }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [rebillsEnabled, setRebillsEnabled] = useState(false)
    const [internalCampaigns, setInternalCampaigns] = useState<InternalCampaign[]>([])
    const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)
    const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false)
    const [defaultInternalCampaign, setDefaultInternalCampaign] = useState<InternalCampaign>(null)

    useEffect(() => {
        handeProductsFilter('')
        if(declineAttempt.internal_campaign_id){
            getInternalCampaign(declineAttempt.internal_campaign_id)
        }
    }, [declineAttempt])

    const handeProductsFilter = async value => {
        console.log(value);
        setIsAutocompleteLoading(true)
        const p:PaginatedResponse<InternalCampaign> = await CampaignsService.search({name: value});
        setIsAutocompleteLoading(false)
        setInternalCampaigns(p.data);
    };

    const getInternalCampaign = async (internal_campaign_id:string) => {
        const internalCampaign:InternalCampaign = await CampaignsService.get(internal_campaign_id);
        setDefaultInternalCampaign(internalCampaign)
    }

    return (
        <>
            <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                <Grid item xs={2}>
                    <Divider />
                </Grid>
                    <Grid item xs={position > 1 ? 1 : 2}>
                        <Typography variant='h6'>Decline Attempt {position} (id: {declineAttempt.id})</Typography>
                    </Grid>
                    {position > 1 && <Grid item xs={1}>
                        <LoadingButton
                            loadingPosition='start'
                            startIcon={<CancelIcon />}
                            variant='outlined'
                            color='error'
                            size='small'
                            onClick={() => onRemoveDeclineAttempt(position)}
                        >Remove Decline Attempt
                        </LoadingButton>
                    </Grid>}
                    <Grid item xs={2}>
                    <Autocomplete
                        fullWidth
                        value={defaultInternalCampaign ?? null}
                        open={isAutocompleteOpen}
                        onOpen={() => {
                            setIsAutocompleteOpen(true);
                        }}
                        onClose={() => {
                            setIsAutocompleteOpen(false);
                        }}
                        isOptionEqualToValue={(option:any, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name}
                        options={internalCampaigns}
                        loading={isAutocompleteLoading}
                        filterOptions={(x) => x}
                        onChange={(_, internalCampaign, reason) => {
                            console.log(reason)
                            if(reason == 'selectOption'){
                                declineAttempt.internal_campaign_id = internalCampaign.id
                                onChangeDeclineAttempt(position, declineAttempt)
                                setDefaultInternalCampaign(internalCampaign)
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Product"
                            onChange={ev => {
                                // dont fire API if the user delete or not entered anything
                                if (ev.target.value !== "" || ev.target.value !== null) {
                                    handeProductsFilter(ev.target.value);
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {isAutocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                                ),
                            }}
                            />
                        )}
                        />
                    </Grid>
            </Grid>
        </>
    )
}

export default DeclineAttemptSection;

