import { Company } from "./company";
import { Role } from "./role";

export interface User {
  token:string;
  email:string;
  name:string;
  roles:Role[]
  created_at:string
  updated_at:string
  role:string
  company:Company
}

export const UserIsSupport = (user:User) => {
  for (const role of user.roles) {
    if (role.slug == 'support') return true;
  }
  return false;
}

export const UserIsAdmin = (user:User) => {
  for (const role of user.roles) {
    if (role.slug == 'admin') return true;
  }
  return false;
}

export const UserIsBankPage = (user:User) => {
  for (const role of user.roles) {
    if (role.slug == 'pages') return true;
  }
  return false;
}

export const UserIsCheckoutPage = (user:User) => {
  for (const role of user.roles) {
    if (role.slug == 'checkout-pages') return true;
  }
  return false;
}