import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import { PaginatedResponse } from "src/models/paginatedResponse";
import { RebillOrder } from "src/models/rebillOrder";
import { Transaction } from "src/models/transaction";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";
import { format } from 'date-fns';
import { CustomerSearchTerms } from "src/content/applications/Customers";

export class CustomersService {
  static async getCustomer(id:number):Promise<Customer> {
    try {
      const { data } = await Api.client.get<Customer>(`customers/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async transactions({id}, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Transaction>> {
    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `customers/${id}/transactions`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.get<PaginatedResponse<Transaction>>(urlWithQuery);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async update({
    id,
    firstName = '',
    lastName = '',
    email = '',
  }:Partial<Customer>):Promise<Customer> {
    var params = {
      firstName,
      lastName,
      email,
    };

    try {
      const { data } = await Api.client.post<Customer>(`customers/${id}`, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    createdFrom = undefined,
    createdTo = undefined,
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Customer>> {
    var params = {
      'firstName': firstName,
      'lastName': lastName,
      'email': email,
      'phone': phone,
      'createdFrom': createdFrom ? format(createdFrom, 'yyyy-MM-dd') : '',
      'createdTo': createdTo ? format(createdTo, 'yyyy-MM-dd') : '',
    };

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const baseUrl = 'customers/search';
  const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Customer>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

}