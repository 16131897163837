import { Card, CardActions, CardContent, Divider, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import TableChartIcon from '@mui/icons-material/TableChart';
import { InternalCampaign } from 'src/models/internalCampaign';
import GridFormInput from 'src/components/GridFormInput';
import { CustomerSearchTerms } from '.';
import GridFormDatePicker from 'src/components/GridFormDatePicker';

function PageHeader({
  searchTerms, 
  isSearching,
  isExporting,
  onSearch,
  onClear,
  onExport,
  campaigns,
}:{
  searchTerms:CustomerSearchTerms, 
  isSearching:boolean,
  isExporting:boolean,
  onSearch:Function,
  onClear:Function,
  onExport:Function,
  campaigns:InternalCampaign[],
}) {
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    createdFrom: undefined,
    createdTo: undefined,
 };

  const methods = useForm({
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<CustomerSearchTerms> = async (values: CustomerSearchTerms) => {
    console.log('on submit')
    onSearch(methods.control._formValues)
  };

  return (
    <>
        <FormProvider {...methods} >
          <Card>
          <form onSubmit={methods.handleSubmit(onSubmitHandler)} >
          <CardContent>
      <Grid container spacing={1} columns={{ xs: 1, sm: 2, md: 4 }}>
        <GridFormInput
            label='First Name'
            name='firstName'
          />
        <GridFormInput
          label='Last Name'
          name='lastName'
        />
        <GridFormInput
          label='Email'
          type='email'
          name='email'
        />
        <GridFormInput
          label='Phone'
          name='phone'
        />
        <GridFormDatePicker
          label='From Date'
          name='createdFrom'
        />
        <GridFormDatePicker
          label='To Date'
          name='createdTo'
        />
      </Grid>
      </CardContent>
      <Divider />
        <CardActions sx={{ justifyContent:'flex-end' }} >
        <LoadingButton
          loading={isSearching}
          loadingPosition='start'
          startIcon={<SearchIcon />}
          variant='outlined'
          type='submit'
        >Show Results</LoadingButton>
        <LoadingButton
          loading={isSearching}
          loadingPosition='start'
          startIcon={<CancelIcon />}
          variant='outlined'
          color='error'
          onClick={() => {
            methods.reset()
            onClear(methods.control._formValues)
          }}
        >Clear</LoadingButton>
        <LoadingButton
          loading={isExporting}
          loadingPosition='start'
          startIcon={<TableChartIcon />}
          variant='outlined'
          onClick={() => onExport()}
        >CSV</LoadingButton>
        </CardActions>
      </form>
      </Card>
      </FormProvider>
    </>
  );
}

export default PageHeader;
