import { useContext, useState } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Collapse
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import GroupTwoTone from '@mui/icons-material/GroupTwoTone';
import CampaignTwoTone from '@mui/icons-material/CampaignTwoTone';
import TransactionsIcon from '@mui/icons-material/PaidTwoTone';
import AppsIcon from '@mui/icons-material/Apps';
import DomainsIcon from '@mui/icons-material/Language';
import PixelsIcon from '@mui/icons-material/BarChart';
import TemplatesIcon from '@mui/icons-material/Brush';
import OffersIcon from '@mui/icons-material/Web';
import DeclineProfilesIcon from '@mui/icons-material/ThumbDownOffAlt';
import PieChartTwoTone from '@mui/icons-material/PieChartTwoTone';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth } from '../../../../providers/AuthProvider';
import { UserIsAdmin, UserIsSupport, UserIsBankPage, UserIsCheckoutPage } from 'src/models/user';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0.6, 2)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const menuAdmin = [
  {
    icon: <PieChartTwoTone/>,
    title: 'Dashboard',
    to: '/dashboard',
  },
  {
    icon: <GroupTwoTone/>,
    title: 'Customers',
    to: '/customers',
  },
  {
    icon: <TransactionsIcon/>,
    title: 'Transactions',
    to: '/transactions',
  },
  {
    icon: <CampaignTwoTone/>,
    title: 'Products',
    to: '/products',
  },
  {
    icon: <DeclineProfilesIcon/>,
    title: 'Decline Profiles',
    to: '/decline-profiles',
  },
  {
    icon: <GroupTwoTone/>,
    title: 'Users',
    to: '/users',
  },
  {
    icon: <AppsIcon/>,
    title: 'Apps',
    to: '/apps',
    separatorTop:true,
  },
  {
    icon: <OffersIcon/>,
    title: 'Offer Management',
    separatorTop:true,
    items: [
      {
        icon: <OffersIcon/>,
        title: 'Offers',
        to: '/offers',
      },
      {
        icon: <TemplatesIcon/>,
        title: 'Templates',
        to: '/templates',
      },
      {
        icon: <DomainsIcon/>,
        title: 'Domains',
        to: '/domains',
      },
      {
        icon: <PixelsIcon/>,
        title: 'Pixel Manager',
        to: '/pixels',
      },
    ]
  },
]

const menuSupport = [
  {
    icon: <GroupTwoTone/>,
    title: 'Customers',
    to: '/customers',
  },
  {
    icon: <TransactionsIcon/>,
    title: 'Transactions',
    to: '/transactions',
  },
]

const menuBankPage = [
    {
      icon: <DomainsIcon/>,
      title: 'Pages',
      to: '/pages',
    },
    {
      icon: <DomainsIcon/>,
      title: 'Domains',
      to: '/domains',
    },
    {
      icon: <DomainsIcon/>,
      title: 'Products',
      to: '/page-products',
    },
    {
      icon: <DomainsIcon/>,
      title: 'Corporations',
      to: '/corporations',
    },
    {
      icon: <DomainsIcon/>,
      title: 'Terms',
      to: '/terms',
    },
    {
      icon: <TemplatesIcon/>,
      title: 'Templates',
      to: '/templates',
    },
]

const menuCheckoutPage = [
  {
    icon: <DomainsIcon/>,
    title: 'Pages',
    to: '/checkout-pages',
  },
  {
    icon: <DomainsIcon/>,
    title: 'Companies',
    to: '/companies',
  },
  {
    icon: <DomainsIcon/>,
    title: 'Domains',
    to: '/domains',
  },
  {
    icon: <TemplatesIcon/>,
    title: 'Templates',
    to: '/templates',
  },
]

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useAuth();

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              { UserIsAdmin(user) && menuAdmin.map((item, key) => <MenuItem key={key} item={item} />) }
              { UserIsSupport(user) && menuSupport.map((item, key) => <MenuItem key={key} item={item} />) }
              { UserIsBankPage(user) && menuBankPage.map((item, key) => <MenuItem key={key} item={item} />) }
              { UserIsCheckoutPage(user) && menuCheckoutPage.map((item, key) => <MenuItem key={key} item={item} />) }
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

function MenuItem({ item }) {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

function SingleLevel({ item }) {
  const { closeSidebar } = useContext(SidebarContext);

  return (
        <ListItem component="div" sx={{
          display:'flex',
          flexDirection:'column',
          '::before': {
            display: 'block',
            content: '""',
            height: '1px',
            width: '100%',
            borderTop: item.separatorTop ? '1px solid rgba(255, 255, 255, 0.1)' : 'none',
            marginTop: item.separatorTop ? 0.5 : 0,
            paddingTop: item.separatorTop ? 0.5 : 0,
          }
        }}>
          <Button
            disableRipple
            component={RouterLink}
            onClick={closeSidebar}
            to={item.to}
            startIcon={item.icon}
          >
            {item.title}
          </Button>
        </ListItem>
  );
};

function MultiLevel ({ item }) {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem component="div" sx={{
          display:'flex',
          flexDirection:'column',
          '::before': {
            display: 'block',
            content: '""',
            height: '1px',
            width: '100%',
            borderTop: item.separatorTop ? '1px solid rgba(255, 255, 255, 0.1)' : 'none',
            marginTop: item.separatorTop ? 0.5 : 0,
            paddingTop: item.separatorTop ? 0.5 : 0,
          }
        }}>
        <Button
          disableRipple
          onClick={handleClick}
          startIcon={item.icon}
        >
          <Box sx={{
            display:'flex',     
            justifyContent: 'space-between',
            width: '100%'
          }}>
          {item.title}
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Button>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarMenu;
