import { FC } from 'react';
import { useFormContext, Controller, useForm } from 'react-hook-form';
import { Grid, TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/lab';
import { format } from 'date-fns';

// ? Type of Props the FormDatePicker will receive
type FormDatePickerProps = {
    name: string;
  } & Partial<DatePickerProps>;
  
  const GridFormDatePicker: FC<FormDatePickerProps> = ({ name, ...otherProps }) => {
    // ? Utilizing useFormContext to have access to the form Context
    const {
      control,
      formState: { errors },
    } = useFormContext();
  
    const {setValue} = useForm();

    return (
      <Grid item xs={1}>
        <Controller
          control={control}
          name={name}
          defaultValue=''
          render={({ field }) => (
            <DatePicker 
              {...otherProps}
              {...field}
              value={field.value}
              onChange={field.onChange}
              renderInput={(props) => <TextField 
                {...props} 
                fullWidth
                error={!!errors[name]}
                helperText={
                  errors[name] ? (errors[name]?.message as unknown as string) : ''
                }
              />}
            />
          )}
        />
      </Grid>
    );
  };
  
  export default GridFormDatePicker;
  