import { Flow } from "src/models/flow";
import { InternalCampaign } from "src/models/internalCampaign";
import { PaginatedResponse } from "src/models/paginatedResponse";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";

export class CampaignsService {
  static async get(internal_campaign_id:string):Promise<InternalCampaign> {
    try {
      const { data } = await Api.client.get<InternalCampaign>(`internal-campaigns/${internal_campaign_id}`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async getAll():Promise<InternalCampaign[]> {
    try {
      const { data } = await Api.client.get<InternalCampaign[]>(`internal-campaigns`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
    user = '',
    external_campaign_id = '',
    active = false,
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<InternalCampaign>> {
    var params = {
      name,
      user,
      external_campaign_id,
      active,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `internal-campaigns/search`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;
    
    try {
      const { data } = await Api.client.post<PaginatedResponse<InternalCampaign>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(internalCampaign:Partial<InternalCampaign>, flows:Partial<Flow>[]):Promise<InternalCampaign> {
    try {
      const { data } = await Api.client.post('internal-campaigns', {
        name:internalCampaign.name, 
        user:internalCampaign.user, 
        data:internalCampaign.data,
        flows
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(internalCampaign:Partial<InternalCampaign>, flows:Partial<Flow>[]):Promise<InternalCampaign> {
    try {
      const { data } = await Api.client.post('internal-campaigns/edit', {
        id:internalCampaign.id, 
        name:internalCampaign.name, 
        user:internalCampaign.user, 
        data:internalCampaign.data, 
        flows
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(campaignID:number):Promise<InternalCampaign> {
    try {
      const { data } = await Api.client.post(`internal-campaigns/${campaignID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(campaignID:number):Promise<InternalCampaign> {
    try {
      const { data } = await Api.client.post(`internal-campaigns/${campaignID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async duplicate(campaignID:number, name:string, username:string):Promise<InternalCampaign> {
    try {
      const { data } = await Api.client.post(`internal-campaigns/${campaignID}/duplicate`, {
        name:name, 
        user:username, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}