import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  CardHeader,
  Link as MuiLink,
  Backdrop,
  styled,
  CircularProgress,
  Button,
  IconButton,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { PaginatedState } from 'src/models/paginatedState';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { DeclineProfile } from 'src/models/declineProfile';

interface DeclineProfilesTableProps {
  className?: string
  paginatedResponse?: DeclineProfile[]
  paginatedState: PaginatedState
  isRefreshing:boolean
  onPageChange:(page:number) => void
  onRowsPerPageChange:(rowsPerPage:number) => void
  onEditDeclineProfile:(internalDeclineProfile:DeclineProfile) => void
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const DeclineProfilesTable: FC<DeclineProfilesTableProps> = ({
  paginatedResponse, 
  paginatedState,
  isRefreshing = false, 
  onPageChange, 
  onRowsPerPageChange,
  onEditDeclineProfile,
}) => {
  const navigate = useNavigate()

  const handlePageChange = async (event: any, newPage: number) => {
    onPageChange(newPage)
  };

  const handleLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value))
  };

  return (
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Product ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResponse?.map((item) => {
              return (
                <TableRow
                  key={item.id}
                  >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.decline_attempts[0]?.internal_campaign_id ?? '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display='flex'>
                    <LoadingButton
                      onClick={() => {
                        onEditDeclineProfile(item)
                      }}
                    >Edit</LoadingButton>
                    </Box>
                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={paginatedResponse?.length ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={paginatedState.page}
          rowsPerPage={paginatedState.per_page}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default DeclineProfilesTable;
